import React, { FC, useMemo } from "react";
import styled, { css } from "styled-components";

export type CarouselItemProps = {
  imageStandard: string;
  imageWebp?: string;
};

export const CarouselItem = styled.figure<CarouselItemProps>`
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: center center;
  background-image: ${({ imageStandard }) => `url(${imageStandard})`};
  height: 12.5em;
  width: auto;

  ${({ imageWebp }) =>
    imageWebp &&
    css`
      background-image: -webkit-image-set(url(${imageWebp}) 1x);
    `}

  @media (min-width: 48em) {
    height: 20em;
  }
`;
