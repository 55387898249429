import { Typography } from "@atoms/typography";
import { FC, useMemo } from "react";
import styled from "styled-components";

export type ReasonTypes = "mallorca" | "vip" | "holidays";

export type WhyFeelingHomeItemProps = {
  type: ReasonTypes;
  reason: string;
};

const MapInfo: Record<
  ReasonTypes,
  { webp: string; standard: string; alt: string }
> = {
  mallorca: {
    webp: "/images/ico_reason1.webp",
    standard: "/images/ico_reason1.png",
    alt: "Mallorca",
  },
  vip: {
    webp: "/images/ico_reason2.webp",
    standard: "/images/ico_reason2.png",
    alt: "VIP",
  },
  holidays: {
    webp: "/images/ico_reason3.webp",
    standard: "/images/ico_reason3.png",
    alt: "Holidays",
  },
};

export const WhyFeelingHomeItem: FC<WhyFeelingHomeItemProps> = ({
  type,
  reason,
}) => {
  const info = useMemo(() => MapInfo[type], [type]);

  return (
    <Container>
      <picture>
        <source srcSet={info.webp} type="image/webp" />
        <img alt={info.alt} src={info.standard} loading="lazy" />
      </picture>
      <Typography>{reason}</Typography>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
