import { HighlightTitle } from "@atoms/highlight-title";
import { WhyFeelingHomeItem } from "@molecules/why-feeling-home-item";
import { FC } from "react";
import { useTranslation } from "next-i18next";
import styled from "styled-components";

export const WhyFeelingHome: FC = () => {
  const { t } = useTranslation("showcase");

  return (
    <Container>
      <HighlightTitle>{t("why-feeling-home")}</HighlightTitle>
      <div className="reasons">
        <WhyFeelingHomeItem type="mallorca" reason="1*" />
        <WhyFeelingHomeItem type="vip" reason="2*" />
        <WhyFeelingHomeItem type="holidays" reason="3*" />
      </div>
    </Container>
  );
};

const Container = styled.div`
  .reasons {
    margin-top: 2em;
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr;

    @media (min-width: 21em) {
      grid-template-columns: repeat(auto-fit, minmax(6em, 1fr));
      align-items: stretch;
    }
  }
`;
