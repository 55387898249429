import { FC, PropsWithChildren } from "react";
import styled, { css } from "styled-components";

export type SectionProps = PropsWithChildren<{ contrast?: boolean }>;

export const Section: FC<SectionProps> = ({ contrast, children }) => {
  return (
    <Container contrast={contrast}>
      <Content>{children}</Content>
    </Container>
  );
};

const Content = styled.div`
  margin: 0 auto;
  @media (min-width: 75em) {
    max-width: 75em;
  }
`;

const Container = styled.section.attrs<{ contrast?: boolean }>((props) => ({
  contrast: props.contrast,
}))`
  padding: 1em;
  box-sizing: border-box;
  ${({ contrast }) => css`
    background-color: ${contrast ? "#f9f9f9" : "#fff"};
  `}
`;
