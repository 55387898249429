import Button from "@atoms/button";
import { Typography } from "@atoms/typography";
import { routes } from "@fhlib/routes";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC } from "react";
import { useTranslation } from "next-i18next";
import styled from "styled-components";

export const AdvertiseBanner: FC = () => {
  const { t } = useTranslation("showcase");
  const router = useRouter();

  return (
    <Container>
      <picture>
        <source srcSet="/images/home_propietarios.webp" type="image/webp" />
        <img
          alt={t("advertise-your-property-with-us")}
          src="/images/home_propietarios.jpg"
          width="1400"
          height="300"
          loading="lazy"
        />
      </picture>
      <div className="claim-container">
        <Typography variant="title2">
          {t("advertise-your-property-with-us")}
        </Typography>
        <Link
          href={routes["homeowners"][router?.locale ?? "en"]}
          passHref
          locale={false}
          legacyBehavior
        >
          <Button as="a" variant="transparent">
            {t("more-info")}
          </Button>
        </Link>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  margin: 1em 0;

  img {
    min-height: 10em;
    max-height: 300px;
    width: 100%;
    height: auto;
  }

  .claim-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
      color: #fff;
      text-align: center;
      font-family: "DomaineDispNar", sans-serif;
    }
  }
`;
