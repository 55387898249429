import React, { FC, PropsWithChildren } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const CarouselCrossfade: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Slider
      pauseOnHover={false}
      pauseOnFocus={false}
      arrows={false}
      fade
      autoplay
      dots={false}
      infinite
      speed={2000}
      autoplaySpeed={5000}
      slidesToShow={1}
    >
      {children}
    </Slider>
  );
};
