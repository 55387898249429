import {
  getDirectusInstance,
  HomeEntity,
  Properties,
  resolveTranslation,
} from "@fhlib/directus";
import { PropertyCardProps } from "@molecules/property-card/property-card";
import { ShowcaseTemplate } from "@templates/showcase";
import { GetServerSideProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  defaultLocale,
}) => {
  const directus = await getDirectusInstance();
  const homeItem = directus.singleton("home");
  const propertiesItem = directus.items("properties");

  const home = (await homeItem.read({
    fields: [
      "translations.*",
      "featured_properties.properties_id",
      "banners.*",
    ] as any,
  })) as HomeEntity;

  const translated = resolveTranslation(
    locale,
    defaultLocale,
    home.translations
  );

  const highlightedPropertiesResponse = await propertiesItem.readByQuery({
    fields: [
      "id",
      "featured_image",
      "title",
      "bedrooms",
      "bathrooms",
      "accommodates_max",
      "pricing_daily",
      "translations.*",
    ] as any,
    filter: {
      id: { _in: home.featured_properties.map((x) => x.properties_id) },
    },
  });

  const highlightedProperties =
    highlightedPropertiesResponse.data as Properties[];

  const properties = highlightedProperties.map<PropertyCardProps>((x) => {
    const translated = resolveTranslation(
      locale,
      defaultLocale,
      x.translations
    );
    return {
      id: x.id,
      bathrooms: x.bathrooms,
      bedrooms: x.bedrooms,
      guests: x.accommodates_max,
      cover: x.featured_image,
      price: x.pricing_daily,
      slug: translated.slug,
      title: translated.title,
    };
  });

  const translations = await serverSideTranslations(locale ?? "en", [
    "common",
    "showcase",
    "property",
    "forms",
  ]);

  return {
    props: {
      claim: translated.claim,
      properties,
      banners: home.banners
        .sort((a, b) => (a.sort ?? 9999) - (b.sort ?? 9999))
        .map((x) => x.directus_files_id),
      ...translations,
    },
  };
};

export type ShowcaseProps = {
  claim: string;
  properties: PropertyCardProps[];
  banners: string[];
};

export default function Showcase({
  claim,
  properties,
  banners,
}: ShowcaseProps) {
  return (
    <ShowcaseTemplate claim={claim} properties={properties} banners={banners} />
  );
}
