import { Typography } from "@atoms/typography";
import { FC, PropsWithChildren } from "react";
import styled from "styled-components";

export const HighlightTitle: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container>
      <Typography as="span" className="highligh-title--text">
        {children}
      </Typography>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  text-align: center;
  
  &::before {
    content: "";
    position: absolute;
    border-top: 1px solid #395d85;
    left: 0;
    top: 50%;
    width: 100%;
    z-index: 0;
  }

  .highligh-title--text {
    position: relative;
    padding: 0 0.5em;
    background-color: #fff;
    font-family: "DomaineDispNar", sans-serif;
    font-size: 1.5em;
    z-index: 100;
    white-space: nowrap;
  }
`;
