import React, { FC, useCallback } from "react";
import styled from "styled-components";
import { Typography } from "@atoms/typography";
import { ProductsGrid } from "@organisms/products-grid/products-grid";
import { LayoutTemplate } from "@templates/layout";
import { Searcher, SearchForm } from "@organisms/searcher";
import Button from "@atoms/button";
import Claim from "@atoms/claim";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { routes } from "@fhlib/routes";
import * as ga from "@fhlib/ga";
import queryString from "query-string";
import { Section } from "@atoms/section";
import { WhyFeelingHome } from "@organisms/why-feeling-home";
import { AdvertiseBanner } from "@organisms/advertise-banner";
import { PropertyCardProps } from "@molecules/property-card/property-card";
import { format } from "date-fns";
import Link from "next/link";
import { CarouselItem } from "@atoms/carousel-item";
import { CarouselCrossfade } from "@organisms/carousel-crossfade";
import { useFHConfig } from "@templates/config";

export type ShowcaseTemplateProps = {
  claim: string;
  properties: PropertyCardProps[];
  banners: string[];
};

export const ShowcaseTemplate: FC<ShowcaseTemplateProps> = ({
  claim,
  properties,
  banners,
}) => {
  const config = useFHConfig();
  const router = useRouter();
  const { t } = useTranslation("showcase");

  const handleSearch = useCallback(
    (form: SearchForm) => {
      ga.event("homes-search", form);
      let raw = queryString.stringify(
        {
          check_in:
            form.checkIn instanceof Date
              ? format(form.checkIn, "yyyy-MM-dd")
              : form.checkIn,
          check_out:
            form.checkOut instanceof Date
              ? format(form.checkOut, "yyyy-MM-dd")
              : form.checkOut,
          guests: form.guests,
        },
        { skipEmptyString: true }
      );
      if (raw) raw = "?" + raw;
      router.push(routes["homes-results"][router?.locale ?? "en"] + raw);
    },
    [router]
  );

  return (
    <LayoutTemplate page="home">
      <StyledBannerContainer>
        <div className="carousel-container">
          <CarouselCrossfade>
            {banners.map((x) => (
              <CarouselItem
                key={x}
                imageStandard={`${config.backUrl}/assets/${x}`}
                imageWebp={`${config.backUrl}/assets/${x}`}
              />
            ))}
          </CarouselCrossfade>
          <Typography variant="body1">{claim}</Typography>
        </div>
        <StyledSearcherContainer>
          <Searcher onSearch={handleSearch} />
        </StyledSearcherContainer>
      </StyledBannerContainer>

      <StyledShowcaseContainer>
        <Typography className="page-title" variant="title1" align="center">
          {t("highlighted-properties")}
        </Typography>

        <ProductsGrid products={properties} />

        <PropertisListFooter>
          <Link
            href={routes["homes-results"][router?.locale ?? "en"]}
            passHref
            locale={false}
            legacyBehavior
          >
            <Button as="a" variant="secondary">
              {t("view-more-properties")}
            </Button>
          </Link>
        </PropertisListFooter>
      </StyledShowcaseContainer>

      <Section>
        <WhyFeelingHome />
      </Section>

      <AdvertiseBanner />
    </LayoutTemplate>
  );
};

const StyledBannerContainer = styled.div`
  position: relative;
  z-index: 0;
  .carousel-container {
    width: 100%;

    > p {
      display: flex;
      height: 100%;
      width: 100%;
      position: absolute;
      color: #fff;
      text-align: center;
      font-size: 1.1em;
      line-height: 1.3em;
      font-weight: 500;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      font-family: "DomaineDispNar", sans-serif;
      text-shadow: 1px 1px 5px rgb(0 0 0 / 50%);
      padding: 0 8%;
      @media (min-width: 300px) {
        font-size: 1.5em;
      }
      @media (min-width: 585px) {
        font-size: 2.5em;
        line-height: 48px;
      }
    }
  }
`;
const StyledSearcherContainer = styled.div`
  position: absolute;
  bottom: -9.5em;

  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
  > * {
    flex: 0.95;
  }

  @media (min-width: 48em) {
    bottom: -2em;
    > * {
      max-width: 78em;
    }
  }
`;
const StyledShowcaseContainer = styled.div`
  margin-top: 10em;
  padding: 0 0.5em;

  .page-title {
    margin-bottom: 1em;
  }

  @media (min-width: 48em) {
    margin-top: 4.5em;
  }
`;

const PropertisListFooter = styled.div`
  margin: 3rem 0;
  text-align: center;
  > button {
    font-size: 1.2em;
  }
`;

const SectionContainer = styled.div`
  margin: 4rem 1rem;
  flex: 1;
`;
